<template>
    <div style="height: calc(-50px + 100vh); overflow-y: scroll;">
        <iframe
            scrolling="auto"
            frameborder="0"
            id="iframe"
            style="height: calc(100% - 1.5rem); width: 100%;"
            src="https://www.baidu.com"
        ></iframe>
    </div>
</template>

<script>

export default {
    name: 'zhanzhu',
}
</script>

<style scoped>
</style>
