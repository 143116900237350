<template>
    <div style="width: 100%; min-height: 100vh; background: rgb(241, 241, 241);">
        <van-nav-bar
            title="个人资料"
            left-arrow
            fixed
            :style="{ backgroundColor: 'rgb(237, 242, 253)' }"
            @click-left="onClickLeft"
        />
        <div style="height: 46px;"></div>
        <div class="tops">
            <img src="/static/image/safety.d3a323b5ad7cca95958707791f3861b1.png" alt="">
            <div class="tes">完善账户信息，更安全</div>
        </div>
        <div class="boxst">
            <div class="hgs" v-for="(item, index) in menuItems" :key="index" @click="navigateTo(item.route)">
                <div class="lfs">
                    <div class="topas">{{ item.title }}</div>
                    <div class="tisg">{{ item.description }}</div>
                </div>
                <div class="rigs">
                    <template v-if="index === 0">
                        去完善
                    </template>
                    <img src="/static/image/right.b9a9c7c64558347505384ad01922580c.png" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { NavBar } from 'vant';

export default {
    components: {
        [NavBar.name]: NavBar,
    },
    data() {
        return {
            menuItems: [
                { title: '完善个人资料', description: '资料更完整，我们的服务更加周到', route: '/userInfo' },
                { title: '卡片管理', description: '如需提现，请绑定银行卡或虚拟币地址', route: '/wallet' },
                { title: '登录密码管理', description: '定期修改登录密码，有利账户安全', route: '/password?type=1' },
                { title: '取款密码管理', description: '定期修改登录密码，有利账户安全', route: '/password?type=2' },
            ],
        };
    },
    methods: {
        onClickLeft() {
            // Handle left arrow click (e.g., go back)
            this.$router.go(-1);
        },
        navigateTo(route) {
            // Navigate to the specified route
            this.$router.push(route);
        },
    },
};
</script>

<style scoped>
.tops {
    background: url(/static/image/safety_back.577bcf768c07205d5e3e2c7949d5677c.577bcf76.png) no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 5rem;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 30px 0;
    min-height: 200px
}

.tops img {
    width: 3rem;
    display: block;
    margin: 0 auto
}

.tops .tes {
    color: #fbfaf5;
    font-size: .3rem;
    text-align: center;
    padding-top: 15px
}

.boxst {
    width: 90%;
    background: #fbfaf5;
    border-radius: 20px;
    position: relative;
    margin: -10px auto 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 20px
}

.boxst .hgs {
    border-bottom: 1px solid #f1f1f1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 1.3rem;
    min-height: 50px
}

.boxst .hgs .lfs {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1
}

.boxst .hgs .lfs .topas {
    font-size: .3rem
}

.boxst .hgs .lfs .tisg {
    margin-top: 4px;
    color: #999;
    font-size: .3rem
}

.boxst .hgs .rigs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #999;
    font-size: .3rem
}

.boxst .hgs .rigs img {
    width: .34rem;
    margin-left: 4px
}

.adds {
    width: 60%;
    margin: 100px auto 0
}

.adds .btntits {
    text-align: center;
    margin-top: 20px;
    font-size: .28rem;
    color: #6c6e82
}

.lis {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 95%;
    margin: 20px auto 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 10px 25px;
    min-height: 80px;
    border-radius: 10px;
    position: relative;
    background: #fbfaf5
}

.lis .lefs {
    width: 1.5rem
}

.lis .cest {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin: 0 20px
}

.lis .cest .type {
    font-size: .4rem;
    font-weight: 700;
    color: #98a8c5;
    margin-top: 6px
}

.lis .cest .num {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-top: 6px;
    color: #98a8c5
}

.lis .cest .num span {
    font-size: .6rem
}

.lis .rigss {
    position: absolute;
    top: 10px;
    right: 10px;
    width: .6rem
}

.tipsh {
    width: 95%;
    margin: 6px auto;
    border-radius: 10px;
    background: #f8f8f8;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 6px
}

.tipsh .tops {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    font-size: .4rem;
    font-weight: 700;
    color: #333;
    height: 1rem
}

.tipsh .tops span {
    font-size: .29rem;
    font-weight: 400
}

.tipsh .tsg {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.tipsh .tsg .tsgs {
    height: .56rem;
    line-height: .56rem;
    color: #a5a9b3;
    font-size: .2rem;
    text-align: center;
    padding: 4px 8px;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    background-image: url(data:image/png;
    base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAAA0CAYAAADPCHf8AAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALCSURBVHgB7d0hcNtAEIXhtWh4y8vDixseXHPz4ibYKW2Kw1UsHt7g8vCWB7u7mYsnss7S3elmam3+b8bjxM6Evaz1tFJWu91uKyLnAixbt1qt7qSyRh+3Aizfpf6xv5TKGk3dX31uBVi+jYak6qehJjx3+ngSYPmuNCTvpZLngOgUsXBU//wG/Adn+thqSM6kgpcJYiG516ffAizfO31cSQXNwfc/BfDhXKfIRmbqBUSniE2QewF8sGZrLTM0kdfsWIQDdnix1pB8lEKDgIQD9k4AP75oSD5IgdgEsZDYeZE/AvhgjVZR/duMvPdDAD+s2fqaW/8eDUg4YKf2hSf2MSur2Wom3mdPC95c5DRbowFhTwtOWbN1kfKDUxPEsKcFjzYpzdZkQNjTglNJzVbKBGFPC15NNltJAQnY04JHo81WckDY04JjR5utnAli2NOCV+vYJbtZAWFPC84Nmq3cCWIsIOxpwavt62YrOyBhirCnBa96l+yWTBD2tODd/pLdlRTShNkv4QQiPOuKJgjwRjzNCcisa32BE/dgFw4WBSRsQiZtQwILZFvs3+2L0gnyWQCfLBzXoa3ND0g4JV/t1o7AibnRcOzP82UFJDRX1e+gDZyIOw3H4+sXcieITY8q9zwFTkyr4RisUSUHJEwPDszh0UO41dVAzgTZCuDPvrGKSQpIqHU5MIc3vcYqZjIg4aMVtS68sVD0GquYlAlirRXTA960h41VzGhAqHXhVLSxipmaIOxbwZvuWGMVczQg7FvBocfc/6U+NkE4MIcn1lh9k0zRgLBvBWessbqeaqxiBgEJB+afBPDjtiQcJjZBmB7wxBqrX1KoFxD2reBMVmMVczhB2LeCF9mNVcw+IOxbwZGixirmOSDsW8GR4sYq5mWCMD3gxU2tcJgmTA9WSuBBG+76WY1NEMIBD9q5jVXMPzyG0oj5jr9QAAAAAElFTkSuQmCC);background-size: cover
}

.usrse {
    background: #fbfaf5;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 20px
}

.usrse .nams {
    font-size: .38rem;
    color: #000;
    vertical-align: middle;
    margin-top: 10px;
    font-weight: 700
}

.usrse .imgsa {
    position: relative;
    height: 2rem;
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: .2rem
}

.usrse .imgsa .bisn {
    width: .8rem;
    position: absolute;
    bottom: .3rem;
    left: 1.4rem
}

.usrse .imgsa img {
    width: 2rem;
    border-radius: 50%
}[class*=van-hairline]:after {
     border: none
 }

.sc {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-bottom: 20px
}

.sc .ssa {
    border-radius: 5px;
    border: 1px solid #f1f1f1;
    width: 2rem;
    height: 1rem;
    line-height: 1rem;
    font-size: .4rem;
    font-weight: 700;
    text-align: center;
    margin-left: 1rem
}

.sc .acti {
    color: #727272!important;
    border: 1px solid #727272!important
}

.usrse {
    background: #fbfaf5;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 6px 20px 0
}

.usrse .nams {
    font-size: .38rem;
    color: #000;
    vertical-align: middle;
    margin-top: 10px;
    font-weight: 700
}

.usrse .imgsa {
    position: relative;
    height: 2rem;
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: .2rem
}

.usrse .imgsa .bisn {
    width: .8rem;
    position: absolute;
    bottom: .3rem;
    left: 1.4rem
}

.usrse .imgsa img {
    width: 2rem;
    border-radius: 50%
}[class*=van-hairline]:after {
     border: none
 }

</style>
