<template>
    <div class="app app-ti_green">
        <router-view />
        <Foot :activeTab="activeTab" @change="onTabChange" />
    </div>
</template>

<script>
import Header from './libs/Header';
import Foot from './libs/Foot';

export default {
    name: 'Main',
    components: {
        Header,
        Foot,
    },
    data() {
        return {
            baseURL: '',
            activeTab: 0,
            routes: ['/', '/index', '/activity', '/kefu', '/zhanzhu', '/mine'],
            daoTime: null
        };
    },
    watch: {
        $route: {
            immediate: true,
            handler(to) {
                this.updateActiveTab(to.path);
            }
        }
    },
    created() {
        this.baseURL = sessionStorage.getItem('baseURL') || '';
        this.updateActiveTab(this.$route.path);
    },
    methods: {
        updateActiveTab(path) {
            const index = this.routes.indexOf(path);
            this.activeTab = index > 0 ? index - 1 : (index == 0 ? 0 : -1);
        },
        onTabChange(index) {
            if (this.activeTab !== index) {
                this.activeTab = index;
                const targetRoute = this.routes[index + 1] || this.routes[1];
                if (this.$route.path !== targetRoute) {
                    this.$router.push(targetRoute).catch(err => {
                        if (err.name !== 'NavigationDuplicated') {
                            throw err;
                        }
                    });
                }
            }
        },
        outLogin() {
            this.$parent.outLogin();
        },
        openDaoTime() {
            this.openDaoTime();
        },
        closeDaoTime() {
            this.$parent.closeDaoTime();
        },
        openGamePage(name, type, code) {
            this.$parent.openGamePage(name, type, code);
        },
        goNav(url) {
            this.$parent.goNav(url);
        },
        getUserInfo() {
            this.$parent.getUserInfo();
        },
        getUserInfoShowLoding() {
            this.$parent.getUserInfoShowLoding();
        },
        doCopy(msg) {
            this.$parent.doCopy(msg);
        },
        showLoading() {
            this.$parent.showLoading();
        },
        hideLoading() {
            this.$parent.hideLoading();
        },
        openKefu() {
            this.$parent.openKefu();
        },
        getAgentLoginUrl() {
            this.$parent.getAgentLoginUrl();
        },
        showTost(type, title) {
            this.$parent.showTost(type, title);
        },
        getBalance() {
            this.$parent.getBalance();
        },
    },
    beforeDestroy() {
        if (this.daoTime) {
            clearInterval(this.daoTime);
        }
        this.daoTime = null;
    },
};
</script>

<style lang="scss" scoped>
</style>
