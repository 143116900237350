<template>
    <div class="acts" :style="{ backgroundColor: 'rgb(237, 242, 253)', minHeight: '100vh' }">
        <div class="pageTop">赞助</div>
        <div style="height: 30px;"></div>
        <div class="lis" v-for="sponsor in sponsors" :key="sponsor.name" @click="tt">
            <div class="lfs">
                <img :src="sponsor.logoSrc" alt="">
                <div class="you">{{ sponsor.name }}</div>
                <div class="te">{{ sponsor.partnershipType }}</div>
            </div>
            <div class="rig">
                <img :src="sponsor.imageSrc" alt="">
            </div>
        </div>
        <van-divider
            dashed
            :style="{ borderColor: '#cccccc', color: '#cccccc', padding: '20px 100px' }"
        >
            没有更多了~
        </van-divider>
    </div>
</template>

<script>
import { Divider } from 'vant';

export default {
    components: {
        [Divider.name]: Divider,
    },
    data() {
        return {
            sponsors: [
                {
                    name: '尤文图斯',
                    partnershipType: '官方区域合作伙伴',
                    logoSrc: '/static/image/ddf471901f2b4fff9ee57015a1698227.png',
                    imageSrc: '/static/image/93b000fa1d3246ce9b90a62c018714af.png',
                },
                {
                    name: '阿斯顿维拉',
                    partnershipType: '官方全球顶级合作伙伴',
                    logoSrc: '/static/image/ddf471901f2b4fff9ee57015a1698227.png',
                    imageSrc: '/static/image/bd72c14c428d41ce8105a0d82a1bb696.png',
                },
            ],
        };
    },
    methods: {
        tt(){
            this.$router.push({ path: '/zhanzhu?type=1' })
        }
    }
};
</script>

<style scoped>
.lis {
    display: flex;
    width: calc(100% - 40px);
    margin: 20px auto 0;
    border-radius: 20px;
    background-color: #f8f9ff;
    box-shadow: 0 4px 20px rgba(199, 212, 255, 0.42);
    overflow: hidden;
    border: .02rem solid #fbfaf5;
}

.lis .lfs {
    height: 100%;
    width: 45%;
    text-align: center;
    color: #98a8c5;
    padding: 20px 0;
}

.lis .lfs img {
    width: 30px;
    display: block;
    margin: 0 auto 10px;
}

.lis .lfs .you {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 5px;
}

.lis .lfs .te {
    font-size: 12px;
}

.lis .rig {
    width: 55%;
    font-size: 0;
    display: flex;
    align-items: center;
}

.lis .rig img {
    width: 100%;
    height: auto;
    object-fit: cover;
}
</style>
