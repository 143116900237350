<template>
    <div style="height: calc(-50px + 100vh); overflow-y: scroll;">
        
    </div>
</template>

<script>

export default {
    name: 'activity',
    data() {
      return {
          kefu_url:""
      }
    },
    created() {
        let that = this;
        that.$apiFun.post('/api/getservicerurl', {}).then(res => {
            console.log(res);
            if (res.code != 200) {
                that.showTost(0, res.message);
            }
            if (res.code == 200) {
                // that.kefu_url = res.data.url;
                window.location.href = res.data.url;
            }
        });
    }
}
</script>

<style scoped>
</style>
