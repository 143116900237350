<template>
    <div>
        <div style="height: 180px;"></div>
        <img class="bancgs" src="/static/image/bank_020021515.png" alt="" @click="$router.back()">
        <div class="topsf">
            <div class="tois">
                <img src="/static/style/tx.0d38194b71b5a32ef1df50b7090ca7f5.png" alt="">
                <div class="dwd">
                    <div class="tisaa">Hi,尊敬的会员用户</div>
                    <div class="newsa">早上好，欢迎来到帮助中心</div>
                    <div class="newsa">若相关问题仍未解决，可咨询在线客服</div>
                </div>
            </div>
            <div style="color: rgb(255, 255, 255); text-align: center; margin-top: 10px; font-size: 20px;">
                {{ pageContent.title }}
            </div>
        </div>
        <div style="color: rgb(102, 102, 102); padding: 0px 20px; box-sizing: border-box;" v-html="pageContent.content"></div>

        <div style="margin-top: 0.48rem; text-align: center; color: rgb(108, 124, 157); padding-bottom: 0.6rem;">
            没有找到解决办法？请联系<a style="color: #bd0b0bb5; font-weight: 600;" @click="contactCustomerService">人工客服</a>解决
        </div>
    </div>
</template>

<script>
export default {
    name: 'boutBallBetInfo',
    computed: {
        pageType() {
            return this.$route.query.type || '1' // 默认为 '1'
        },
        pageContent() {
            switch(this.pageType) {
                case '1':
                    return {
                        title: '关于我们',
                        content: `
              <p>关于我们</p>
<p>&nbsp;　　新葡京集团线上娱乐成立于2007年，我们拥有着一支来自世界的博彩界精英服务团队，并与亚洲最大博彩软件提供商合作，是亚洲领先的在线博彩网站，我们提供体育投注，网上真人赌城，在线扑克和在线电子游艺。我们从2007年进入中国和亚洲市场，已经取得了菲律宾First Cagayan 的充分授权及监管。严格按照这些管理机构发布的规则进行所有运营。</p>
<p>&nbsp;</p>
<p>　　安全和私密博彩环境以及产品的完整性为您提供的在线博彩体验。我们拥有目前世界上最先进的安全措施和游戏结果审计流程，从而确保我们的会员有一个安全公平的游戏环境。在我们的隐私保密范围之内，我们将为您的所有信息保密，决不分享或出售给第三方。</p>
<p>&nbsp;</p>
<p>　　我们为您提供全世界最多种类的体育赛事、不同的体育投注玩法以及最佳赔率。在我们的在线娱乐场，我们为您提供各种各样的现场游戏和老虎机游戏。我们的真人荷官及现场娱乐也是无可挑剔的，设有 7×24小时在线监测，确保每一局游戏结果均为现场真实的游戏结果。在新葡京集团我们保证您可以享受到世界上最顶级的在线博彩娱乐。</p>
<p>&nbsp;</p>
<p>　　我们提供训练有素和亲切的客户服务，全天候24小时的在线为客户们服务。我们保证以快速、礼貌和有效的方式为玩家们处理问题。</p>
<p>&nbsp;</p>
<p>　　我们向您提供安全方便的各种支付方式。我们坚持以“了解你的客户”和“反洗钱”为一贯宗旨，并配合第三方金融监管机构，以确保遵守最高标准。</p>
<p>&nbsp;</p>
<p>　　我们的使命是为每一位会员提供最精彩的游戏体验，请随时通过电话或电子邮件向我们提出您的意见或建议。</p>
<p>&nbsp;</p>
            `
                    }
                    case '2':
                    return {
                        title: '常见问题',
                        content: `
              <p>常见问题</p>
<p>1.新葡京集团线上娱乐实力怎样？</p>
<p>您好，请参阅主页“关于我们”。</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>2.在贵公司进行游戏是否安全？</p>
<p>您好，本公司系统绝对安全。我们决不泄漏客户的个人资料给任何商业机构。此外，我们亦要求有交易往来的银行，信用卡中转代理等严格保密客户的资料。所有的存款将视为贸易户口，并不会交给其它的人士进行。</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>3.网上博彩是否合法？</p>
<p>您好，我们是合法注册于菲律宾之博彩公司,一切博彩操作皆遵从菲律宾政府条约,不受其他地区管辖，请根据您自身的风险承受能力及当地法律决定是否投注。本公司不能亦不会接受任何人士违犯当地法律所引致之任何责任。</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>4.在新葡京集团线上娱乐进行投注是否有年龄限制？</p>
<p>您好，是的，投注合法年龄必须年满18岁。</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>5.开户是否要填写真实姓名？</p>
<p>您好，基于安全理由，提款时财务部会按照注册姓名进行审核，银行卡户名必须与注册姓名一致方可提款，所以请您在开户时填写的真实姓名必须与您提款的银行卡户名一致。</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>6.忘记密码怎么办？</p>
<p>您好，请您联系7*24小时在线客服核对身份后申请更改密码。</p>
<p>&nbsp;</p>
            `
                    }
                case '3':
                    return {
                        title: '隐私政策',
                        content: `
              <p>隐私政策</p>
<p>1、新葡京集团十分尊重及重视会员的隐私权，我们将竭力保证您的信息与数据安全，让客户无后顾之忧，这是我们一直遵循的隐私权政策宗旨。</p>
<p>&nbsp;</p>
<p>2、新葡京集团绝不会透露任何能识别个人身份的资料给任何第三方，除非收到法庭传票或应可行法律的要求及判决。我们有权通过网站向有关付款平台服务提供商以及金融保险机构提供必要的个人信息以完成付款要求。</p>
<p>&nbsp;</p>
<p>3、会员提供的所有个人信息，其传送均通过128位SSL加密的安全端口，并存放在普通公众无法进入的保密环境之中。所有数据的内部出入将受到严格限制和严密监控。</p>
<p>&nbsp;</p>
<p>下列是关于客户个人信息隐私权保护规则的内容：</p>
<p>&nbsp;</p>
<p>网站服务由新葡京集团提供。为了让客户在这能享受到安全愉悦的服务体验，对待客户的个人隐私，本公司本着严谨的态度。</p>
<p>为了让客户享受进一步服务，有时我们可能需要客户提供个人信息。本政策将会涵盖相关客户个人信息的使用事项，同时也涉及相关信息的记录程序。有时，客户接受进一步服务前，可能需要客户提供个人信息（如：姓名，通信地址，邮箱地址，会员编号，电话或手机号码，出生日期或支付信息），任何情况下，我们都将对照此政策来处理您的个人信息。在使用您的个人信息时我们将适用现行的法律条款，并竭力采取最佳最稳妥的方式处理。同时，我们也将监控您在本网站的活动内容，可能包括您的访问量，访问页面，网络服务的原始域名等，这将有助于我们建立客户个人文件数据。其中部分资料将被聚合统计，意旨我们将不能对其单独进行检验。</p>
<p>&nbsp;</p>
<p>数据机密性</p>
<p>新葡京集团将尽全力保护客户提供给我们的个人资料，不会蓄意将该数据泄漏给外人，除非此政策条款里有明文规定。所有新葡京集团员工都被要求遵循该政策条款。员工有义务严密的保守客户的个人信息，即使员工离开新葡京集团后仍须坚守此义务。您本人也须保护好您的个人信息，严守您的帐户密码，不应将此透露给其他人。</p>
<p>&nbsp;</p>
<p>服务声明</p>
<p>出于服务质保，防止诈骗和法规遵循方面的原因，我们将对客户的通信记录进行保存和监控（如：电话和邮件记录）。客户有权要求我们勿将其个人信息直接用于市场推销。如果您想行使此权利，客户应准备以下；书面提交申请提供可以证明您身份的信息（如：账号，用户名，注册信息）写明个人信息哪里不正确及需修正的内容。隐私权保护规则的认同一旦客户在新葡京集团注册开户就代表您认同此隐私权保护规则条款。我们将定期更新此政策条款，因此我们建议客户您也定期进行查阅。如果我们对该政策有所更改，我们将在相关网页上发布消息。政策更改后，客户持续使用新葡京集团网站将被视为客户认同该政策的更改。如果用户不接受此政策的更改，您可以选择停用我们的网站服务。如果该政策条款与其它局部特殊条款产生分歧，将适用局部特殊条款。</p>`
                    }
                case '4':
                    return {
                        title: '免责说明',
                        content: `
                        <p>免责说明</p>
<p>1、每日取款上限1000000.00元人民币，完成全额有效投注，手续费全免；</p>
<p>&nbsp;</p>
<p>2、取款到账时间一般3-5分钟，如遇到银行系统维护或财务清算等不可抗拒因素，到账将有所延迟或暂停出款；</p>
<p>&nbsp;</p>
<p>3、所有线上博彩在未申请优惠情况下需完成存款一倍有效流水才可申请提款，申请优惠请参照相关优惠流水要求；</p>
<p>&nbsp;</p>
<p>4、公司保留权利审核会员帐号；</p>
<p>&nbsp;</p>
<p>5、各类游戏和局/未接受/取消注单，不纳入有效投注计算。 运动博弈游戏项目，大赔率玩法计算有效投注金额，小赔率玩法计算输赢金额为有效投注（大赔率产品包括：过关、波胆、总入球、半全场、双胜彩、冠军赛等）；</p>
<p>&nbsp;</p>
<p>如有任何疑问，请咨询24小时在线客服。</p>
<p>&nbsp;</p>
                        `
                    }
                case "5":
                    return {
                        title: "联系我们",
                        content:`<p>联系我们</p>
<p>一、合营计划联系方式</p>
<p>&nbsp;</p>
<p>合营部Skype&nbsp;</p>
<p>live:.cid.abd79c917c885961</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>二、客服邮箱</p>
<p>&nbsp;</p>
<p>service@新葡京ames.vip</p>
<p>&nbsp;</p>
<p>三、在线客服</p>
<p>&nbsp;</p>
<p>我们的客服人员持续秉持着尽心尽责的服务态度，在线客服全天24小时为您提供咨询和帮助</p>`
                    }
                case "6":
                    return {
                        title: "代理加盟",
                        content:`<p>代理加盟</p>
<p>加盟新葡京自己当老板真正0投资0风险稳赚佣金</p>
<p>全新代理模式双重返佣无上限</p>
<p>&nbsp;</p>
<p>辉煌人生 由此触动</p>
<p>新葡京集团成立于2007年，作为国际最具代表性地位的知名博彩公司，拥有实体赌场与线上博彩双执照，提供最广范围并最具竞争力的真人百家乐，龙虎斗，体育博彩，赌场老虎机，虚拟游戏，和扑克游戏等，多年来稳健的经营，成功地将用户扩展至全球各地，我们真诚为您打造最高档次的合作平台，最好的代理加盟方案！</p>
<p>&nbsp;</p>
<p>什么是代理</p>
<p>代理就等于自己开公司当老板一样，自己坐庄。新葡京集团重金扶持您当老板，手把手教您创业致富，真正0投资0风险稳收益，让您睡觉都在赚钱。</p>
<p>&nbsp;</p>
<p>最好的代理加盟方案，八大加盟优势让您无法抗拒！</p>
<p>1.首创全新代理模式，最高的返佣模式；</p>
<p>2.零成本，零风险，高回报，佣金结算快速到账；</p>
<p>3.双重收益，无论线下客户输赢，您都有稳定的高收入；</p>
<p>4.提款快速金额不封顶，实力雄厚；</p>
<p>5.营运多年，用户庞大，深受玩家爱戴；</p>
<p>6.拥有实体赌场与线上博彩执照，信誉保证；</p>
<p>7.多年大力推广，品牌享誉国际，代理可坐享广告名牌效应；</p>
<p>8.数据信息详尽，可查阅下线存款及投注记录。</p>
<p>&nbsp;</p>
<p>新葡京集团给予合作伙伴最高的盈利回报只要您成为我们的代理，只要您拥有人脉或用心推广，积极发展下线，无需任何费用，亏损统一由我们承担，让您实实在在坐等高收益，睡觉都在赚钱。</p>
<p>&nbsp;</p>
<p>加盟新葡京集团，您的财富选择，安全，可靠。</p>
<p>携手新葡京月赚百万，邀您加入!</p>`
                  }
                case "7":
                    return {
                        title: "博彩责任",
                        content:`<p>博彩责任</p>
<p>一、竞猜责任</p>
<p>新葡京集团是一个专业在线网上娱乐城。然则我们强烈建议所有玩家都保持一个娱乐的心态来面对所有游戏。将会通过极致的用户体验、颠覆性娱乐产品、超本土化的个性服务来为所有玩家提供一个舒适健康的娱乐环境。 我们希望所有玩家在游戏的同时能及时调整好自己的心态， 切勿由于过度沉迷游戏而造成个人经济、事业、家庭等各个方面受到影响。 对此，我们强烈建议阁下重新审视自己的行为，明确和调整自己的娱乐心态， 重新投入正常的生活和工作中来。</p>
<p>&nbsp;</p>
<p>二、超过18岁</p>
<p>阁下必须超过18岁（或在阁下居住的管辖范围内不被视为未成年人）才可进入此网站。 任何未满18岁的人不得在我们网站开户和投注，我们将以严谨的态度看待和处理此问题。</p>
<p>&nbsp;</p>
<p>三、赌博问题防护</p>
<p>我们强烈建议阁下定期审视您的竞猜行为，检验自己是否投入过度。</p>
<p>&nbsp;</p>
<p>1.有否曾因赌博而耽误工作时间?</p>
<p>2.赌博有否影响您的声望?</p>
<p>3.赌博之后，您是否感到自责?阁下有否曾经用赌博所赢得的钱来还债，或者解决财政因难?</p>
<p>4.赌博有否导致您的抱负及效率减低?</p>
<p>5.输钱后，你是否感觉您一定要尽快回来赚回您所失去的?</p>
<p>6.赢钱后，您是否有一种强烈的欲望，回来赢得更多?</p>
<p>7.您是否时常赌博至分文都用尽?</p>
<p>8.你是否借钱，作赌博之用?</p>
<p>9.你是否出卖任何物件，作赌博之用?</p>
<p>10.你是否不愿意用 "赌本" 来付正常的开支?</p>
<p>11.赌博是否令您疏忽您自己及您家人的健康快乐?</p>
<p>12.您是否花比预计更多的时间在赌博上?</p>
<p>13.您是否以赌博来逃避忧虑及困难?您是否犯罪或考虑犯罪，为赌博筹备资金?</p>
<p>14.赌博有否令您有睡眠的困难?</p>
<p>15.围绕您的争执、失望或挫败有否造成赌博的推动力?</p>
<p>16.您是否为数小时的赌博而有冲动去庆祝好运?</p>
<p>17.您是否因赌博的结果而自毁或自杀?</p>
<p>&nbsp;</p>`
                  }
                // 添加更多的 case 来处理其他类型
                default:
                    return {
                        title: '帮助中心',
                        content: '<p>请选择一个具体的帮助主题。</p>'
                    }
            }
        }
    },
    methods: {
        contactCustomerService() {
            this.$router.push({ path: '/kefu' });
        }
    }
}
</script>

<style scoped>
*{
    font-size:12px
}
/* Add any additional styles as needed */
.bancgs {
    position: fixed;
    top: 10px;
    left: 10px;
    width: 30px;
    opacity: .8;
    z-index: 200
}

p {
    -webkit-margin-before: 5px!important;
    margin-block-start: 5px!important;
    -webkit-margin-after: 5px!important;
    margin-block-end: 5px!important
}

.inputsw {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 999;
    cursor: pointer
}

.van-tab--active {
    color: #108ee9
}

.van-tabs__line {
    background-color: #108ee9
}

.van-cell {
    padding: 5px 8px
}

.sdg .van-field__label {
    width: .2rem
}[class*=van-hairline]:after {
     border: none
 }

.sdgg .van-popup {
    border-radius: 15px 15px 0 0
}

.stddss .van-field__control {
    font-size: .5rem
}

.van-tabbar-item__icon img {
    display: block;
    height: 34px
}

.van-tabbar-item {
    font-size: 14px
}

.van-tabbar {
    height: 68px
}

.van-tabbar-item--active {
    background-color: #f3f6ff
}
.bsd {
    width: 90%;
    background: #fdfdfd;
    border-radius: 10px;
    padding: 20px 10px;
    margin: 10px auto 0
}

.topsf {
    background-size: 100% 100%;
    height: 180px;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(to right, #8B4513, #FFD700);
  /* padding: 20px; */
    border-radius: 10px;
}

.topsf .tois {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 20px;
    border-bottom: 1px solid #fbfaf5;
    margin: 0 auto;
    width: 90%;
    color: #fbfaf5
}

.topsf .tois .tisaa {
    font-size: 16px;
    font-weight: 700
}

.topsf .tois .newsa {
    margin-top: 6px;
    font-size: 10px
}

.topsf .tois img {
    width: 50px;
    margin-right: 15px
}

.bosfs {
    border-radius: 15px;
    padding: 4px 12px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 90%;
    margin: 15px auto 0;
    background-size: 100% 100%;
    -webkit-box-shadow: 0 .04rem .2rem 0 rgba(93,114,162,.11);
    box-shadow: 0 .04rem .2rem 0 rgba(93,114,162,.11)
}

.bosfs .hgsw {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    height: 40px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-bottom: 1px solid #f5f0f0
}

.bosfs .hgsw .firsimg {
    width: 24px
}

.bosfs .hgsw .tit {
    color:#909090;
    margin-left: 10px
}

.bosfs .hgsw .tisf {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin: 0 12px;
    color: #a2aec8;
    text-align: right
}

.bosfs .hgsw .rigiong {
    width: 6px
}

.topsf {
    background-size: 100% 100%;
    height: 180px;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(to right, #8B4513, #FFD700);
  /* padding: 20px; */
    border-radius: 10px;
}

.topsf .tois {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 20px;
    border-bottom: 1px solid #fbfaf5;
    margin: 0 auto;
    width: 90%;
    color: #fbfaf5
}

.topsf .tois .tisaa {
    font-size: 16px;
    font-weight: 700
}

.topsf .tois .newsa {
    margin-top: 6px;
    font-size: 10px
}

.topsf .tois img {
    width: 50px;
    margin-right: 15px
}

.bosfs {
    border-radius: 15px;
    padding: 4px 12px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 90%;
    margin: 15px auto 0;
    background-size: 100% 100%;
    -webkit-box-shadow: 0 .04rem .2rem 0 rgba(93,114,162,.11);
    box-shadow: 0 .04rem .2rem 0 rgba(93,114,162,.11)
}

.bosfs .hgsw {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    height: 40px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-bottom: 1px solid #f5f0f0
}

.bosfs .hgsw .firsimg {
    width: 24px
}

.bosfs .hgsw .tit {
    color:#909090;
    margin-left: 10px
}

.bosfs .hgsw .tisf {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin: 0 12px;
    color: #a2aec8;
    text-align: right
}

.bosfs .hgsw .rigiong {
    width: 6px
}


</style>
